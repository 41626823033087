<template>
    <div>
        <router-view></router-view>
        <ser-ask></ser-ask>
        <app-footer></app-footer>
    </div>
</template>
<script>
import AppFooter from '@/components/layer/AppFooter'
import SerAsk from '@/components/layer/SerAsk'
export default {
  components: {
    AppFooter,
    SerAsk
  }
}
</script>

<style lang="scss" scoped>

</style>
